(function($) {

    if ( $('.js-ccbb-load').length) {
        $(window).load(function() {
            setTimeout(function(){
                $('.js-ccbb-load').removeClass('isVisible');
            }, 1000);
        });
    }

    // Load Events
    $(window).load(function() {


        if ( $(window).width() > 1023 && $('.js-ccbb-skrollr').length) {
            skrollr.init();
        }

        // disable skrollr if the window is resized below 768px wide
        $(window).on('resize', function () {
            if( $('.js-ccbb-skrollr').length) {
                skrollr.get().refresh();
            }

            if ($(window).width() <= 1023 && $('.js-ccbb-skrollr').length) {
                skrollr.init().destroy(); // skrollr.init() returns the singleton created above
            }
        });
    });

})(jQuery); // Fully reference jQuery after this point.