(function($) {

    // Load Events
    $(window).load(function() {

        $('.js-slick-books').slick();

        AOS.refresh();


        if ( $(window).width() > 1023 && $('.js-book-profile-skrollr').length) {
            // /$('.js-book-profile-skrollr').css('height', ($(window).height() - $('.js-site-head').outerHeight()));
            skrollr.init();
        }

        /**
         *
         * Switch views
         *
         */
        var currentView = 'viewGrid';

        $('.js-toggle-view').on('click', function(evt) {
            evt.preventDefault();

            currentView = $(this).data('toggle');

            $('.js-view').addClass('isHidden');
            $('#'+currentView).removeClass('isHidden');

            $('.js-toggle-view').removeClass('isActive');
            $(this).addClass('isActive');

            AOS.refresh();

            scrollToBooks();
        });

        $('.js-toggle-view-single').on('click', function(evt) {
            evt.preventDefault();
            var lastView = currentView;
            currentView = $(this).data('toggle');
            $(this).data('toggle',lastView);

            $('#'+lastView).addClass('isHidden');
            $('#'+currentView).removeClass('isHidden');

            if(currentView === 'viewGrid') {
                $(this).find('i.icon').addClass('icon-list');
                $(this).find('i.icon').removeClass('icon-grid');
            } else {
                $(this).find('i.icon').addClass('icon-grid');
                $(this).find('i.icon').removeClass('icon-list');
            }
        });



        /**
         *
         * Ajax Books Filter
         *
         */

        $('.js-filter-books').on('click', function(evt){
            evt.preventDefault();

            // active states
            $('.js-filter-books').parent().removeClass('isActive');
            $(this).parent().addClass('isActive');
			
			var cat = $(this).data('cat');
			
			// show intro text
			$('.introduction-text-item').removeClass('isActive');
			$('.introduction-text-item#intro-'+cat).addClass('isActive');

            // load new books
            loadBooks(cat);

            scrollToBooks();
        });

        function scrollToBooks() {
            // setTimeout(function() {
            //     $('html, body').animate({
            //       scrollTop: $('#bookResults').offset().top - 227
            //     }, 250);
            // }, 500);
        }

        function loadBooks(category) {
            $.ajax({
                url: ajaxurl,
                type: 'POST',
                data: {
                    action : 'ajax_load_books',
                    category : category
                },
                beforeSend: function() {
                    // show loader
                    $("#bookResults").html('<div class="post-loading"></div>');
                },
                success: function(html) {
                    if(html) {
                        // Loads new html
                        $("#bookResults").html(html);
                        // fire slick
                        $('.js-slick-books').slick();
                        // display correct view
                        $('#bookResults .js-view').addClass('isHidden');
                        $('#bookResults #'+currentView).removeClass('isHidden');
                    } else {
                        // results
                        $("#bookResults").html('<p class="text-center  rhythm-p-2">No results!</p>');
                    }

                }
            });
            return false;
        }



    });

})(jQuery); // Fully reference jQuery after this point.
