(function($) {

    // Load Events
    $(window).load(function() {

        /**
         *
         * Tabs toggle
         *
         */
        $('.js-tabs-toggle').on('click', function(evt) {
            evt.preventDefault();
            var item = $(this).data('target');
            var type = $(this).data('type');

            $('.js-tabs-toggle').not($(this)).removeClass('isActive');

            // close others

            $('#'+item).toggleClass('isHidden');
            $(this).toggleClass('isActive');

        });

        $('.js-tabs-group-toggle').on('click', function(evt) {
            evt.preventDefault();
            var item = $(this).data('target');
            var type = $(this).data('type');

            $('.js-tabs-group-toggle').not($(this)).removeClass('isActive');

            // close others

                $('.js-tabs-'+type).not($('#'+item)).addClass('isHidden');
                $('#'+item).removeClass('isHidden');
                $(this).addClass('isActive');

            setTimeout(function() {
                $('html, body').animate({
                    scrollTop: $('#'+item).offset().top - 140
                }, 500);
            }, 500);

        });

    });

})(jQuery); // Fully reference jQuery after this point.