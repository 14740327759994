(function($) {

    $(document).ready(function() {

        /*
         * Alt Header on scroll
         */
        stickHeader();
        window.addEventListener('scroll', function(e){
            stickHeader();
        });

        function stickHeader() {
            var distanceY = window.pageYOffset || document.documentElement.scrollTop,
                shrinkOn = 10,
                header = $('.js-site-head');
            if (distanceY > shrinkOn) {
                header.addClass('isSticky');

            } else {
                if (header.hasClass('isSticky')) {
                    header.removeClass('isSticky');
                }
            }
        }





        /**
         * Menu Toggle
         */
        var menuOpen = false;
        $('.js-menu-toggle').on('click', function(evt) {
            evt.preventDefault();

            $(this).toggleClass('isOpen');
            $('.js-nav-primary').toggleClass('isOpen');
            $('.js-site-head').toggleClass('isOpen');
            $('.js-site-wrapper').toggleClass('isOpen');
            $('html').toggleClass('isOpen');
        });

        $('.js-menu-close').on('click', function(evt) {
            evt.preventDefault();
            closeMenu();
        });


        function closeMenu() {
            $('.js-menu-toggle').removeClass('isOpen');
            $('.js-nav-primary').removeClass('isOpen');
            $('.js-site-wrapper').removeClass('isOpen');
            $('.js-site-head').removeClass('isOpen');
            $('html').removeClass('isOpen');
        }

        $(window).resize(function(){
            //closeMenu();
        });



         /**
         * Accordions
         * Config in data attributes
         */
        $('.js-accordion-toggle').on('click', function(evt) {
            evt.preventDefault();

            $(this).parent().toggleClass('isClosed');
        });



        /**
         *
         * Footer accordions
         *
         */
        $('.js-footer-toggle').on('click', function(evt) {
            evt.preventDefault();

            $(this).toggleClass('isActive');
            $(this).next('.foot-accordion-body').toggleClass('isHidden');
        })





        /**
         * Simple Toggle
         * Show and hide item
         */
        $('.js-toggle').on('click', function(evt) {
            evt.preventDefault();

            var toggle = $(this).data('toggle');

            $('#'+toggle).toggleClass('isHidden');
        });




        /**
         * Slick JS
         * Config in data attributes
         */
        $('.js-slick').slick();



        /**
         *
         * Scroll effects
         *
         */

        if(!$('body').hasClass('single-product')) {
            AOS.init({
                once: true,
                offset: 50
            });
        }


        $('.js-slow-scroll').each(function(){
            var $obj = $(this); // assigning the object

            $(window).scroll(function() {
                var speed;
                $obj.data('speed') ? speed = $obj.data('speed') : speed = 2;
                var yPos = ($(this).scrollTop() / speed);

                // Move the background
                $obj.css({ transform: 'translateY( '+yPos+'px)' });
            });
        });



        /*
         * Smooth Scroll
         */
        $('a[href*="#"]:not([href="#"])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $('html, body').animate({
                  scrollTop: target.offset().top - 150
                }, 1000);
                return false;
              }
            }
          });


        /**
         *
         * Social Share popup
         *
         */
        $('a.js-social-share').on('click', function(){
            newwindow=window.open($(this).attr('href'),'','height=540,width=600');
            if (window.focus) {newwindow.focus()}
            return false;
        });


    });

})(jQuery); // Fully reference jQuery after this point.
