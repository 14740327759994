(function($) {

    // Load Events
    $(window).load(function() {


        /*
         * Post nav fixed on scroll
         */

        if($('.js-book-profile-skrollr').length) {
            window.addEventListener('scroll', function(e){
                stickProfileBook();
            });

            $(window).resize(function(){
                stickProfileBook();
            });
        

            var footerOffset, bookOffset, bookHeight, distanceY, headShrink;

            bookOffset = $('.js-book-profile-skrollr').offset().top;
            footerOffset = $('.site-foot').offset().top;
            bookHeight =$('.js-book-profile-skrollr').outerHeight();
            headShrink = 37;
        }

        function stickProfileBook() {
            if($(window).width() > 1023) {
                distanceY = window.pageYOffset || document.documentElement.scrollTop;
                //bookOffset = $('.js-book-profile-skrollr').offset().top;

                if(distanceY >= (bookOffset-headShrink) && (distanceY + bookHeight + (bookOffset-headShrink)) >= footerOffset) {
                    $('.js-book-profile-skrollr').removeClass('isFixedTop');
                    $('.js-book-profile-skrollr').addClass('isFixedBottom');
                    //bookOffset = $('.js-book-profile-skrollr').offset().top;
                }
                else if(distanceY >= headShrink) {
                    $('.js-book-profile-skrollr').addClass('isFixedTop');
                    $('.js-book-profile-skrollr').removeClass('isFixedBottom');

                }
                else {
                    $('.js-book-profile-skrollr').removeClass('isFixedBottom');
                    $('.js-book-profile-skrollr').removeClass('isFixedTop');
                }
            } else {
                $('.js-book-profile-skrollr').removeClass('isFixedBottom');
                $('.js-book-profile-skrollr').removeClass('isFixedTop');
            }
        }

    });

})(jQuery); // Fully reference jQuery after this point.
