(function($) {

    // Load Events
    $(document).ready(function() {


        /*
         * Post nav fixed on scroll
         */

        if($('.js-post-nav').length) {
            var postNav = $('.js-post-nav'),
                postNavOffset = postNav.offset().top,
                shrinkOn;

            setTimeout(function(){
                stickPostNav();
            }, 200);

            if ( $(window).width() > 1023) {
                window.addEventListener('scroll', function(e) {
                    stickPostNav();
                });
            } else {
                $(window).scrollEnd(function(){
                    stickPostNav();
                }, 100);
            }

        }

        function stickPostNav() {
            var distanceY = window.pageYOffset || document.documentElement.scrollTop;
            siteHead = $('.js-site-head');
            shrinkOn = postNavOffset - siteHead.outerHeight();

            if (distanceY > shrinkOn) {
                postNav.addClass('isSticky');
                postNav.css({'top' : siteHead.outerHeight()});
                postNav.parent().css({'padding-top': postNav.outerHeight()})

            } else {
                if (postNav.hasClass('isSticky')) {
                    postNav.removeClass('isSticky');
                    postNav.css({'top' : 'auto'});
                    postNav.parent().css({'padding-top': '0px'})
                }
            }
        }

        //$("#postResults").prepend('<div class="post-loading"></div>');
        var $postgrid = $('#postResults').imagesLoaded( function() {
          // init Masonry after all images have loaded
            $postgrid.masonry({
                itemSelector: '.post-grid__item',
                resize: true,
                transitionDuration: 0
            });
            AOS.refresh();
        });

        function loadPosts(page, type, category, keyword) {
            $.ajax({
                url: ajaxurl,
                type: 'POST',
                data: {
                    action : 'ajax_load_posts',
                    page : page,
                    type : type,
                    category : category,
                    keyword : keyword
                },
                beforeSend: function() {
                    // show loader
                    $("#postResults").append('<div class="post-loading"></div>');
                },
                success: function(html) {
                    if(html) {
                        // update page
                        $('.js-posts-load-more').attr('data-page', (parseInt(page)+1));

                        // remove loader
                        $("#postResults .post-loading").remove();
                        // Loads new html
                        $("#postResults").append(html);

                        $postgrid.masonry('reloadItems');
                        $postgrid.imagesLoaded( function() {
                            $postgrid.masonry();
                            AOS.refresh();
                        });

                    } else {
                        // results
                        $("#loadMorePosts").remove();
                    }

                }
            });
            return false;
        }

        $('.js-posts-load-more').on('click', function(evt){
            evt.preventDefault();
            // load new posts
            loadPosts(
                $(this).attr('data-page'),
                $(this).data('type'),
                $(this).data('category'),
                $(this).data('keyword')
            );
        });


        $('.js-toggle-dropdown-filter').on('click', function(evt) {
            evt.preventDefault();
            $(this).parent().toggleClass('isOpen');
        });
    });

    // extension:
    $.fn.scrollEnd = function(callback, timeout) {
      $(this).scroll(function(){
        var $this = $(this);
        if ($this.data('scrollTimeout')) {
          clearTimeout($this.data('scrollTimeout'));
        }
        $this.data('scrollTimeout', setTimeout(callback,timeout));
      });
    };

})(jQuery); // Fully reference jQuery after this point.
