(function($) {

    // Load Events
    $(window).load(function() {

        /*
         * Show timeline nav after banner
         */
        var timeLineOpen = false;
        var showNavOn = $('.js-banner').outerHeight() - $('.js-site-head').outerHeight();
        var hideNavOn =
            ($('.js-banner').outerHeight() + $('.js-timeline').outerHeight())
            - $(window).height();
        var timelineNav = $('.js-timeline-nav');
        var timeline = $('.js-timeline');

        window.addEventListener('scroll', function(e){
           hideNavOn =
                ($('.js-banner').outerHeight() + $('.js-timeline').outerHeight());

            var distanceY = window.pageYOffset || document.documentElement.scrollTop;

            if ((distanceY > showNavOn) && (distanceY < hideNavOn) && timeLineOpen)  {
                timelineNav.removeClass('isHidden');
            } else {
                timelineNav.addClass('isHidden');
                resetTimeline();
            }
        });


        /**
         *
         * Toggle nav
         *
         */
        $('.js-timeline-nav-toggle').on('click', function(evt) {
            evt.preventDefault();

            $('.js-timeline-nav').toggleClass('isOpen');
            $('.js-timeline').toggleClass('isOpen');
            $(this).toggleClass('isOpen');

            var text = $(this).data('text');
            $(this).data('text', $(this).find('span').text());
            $(this).find('span').text(text);
        });


        $('.js-timeline-share-toggle').on('click', function() {
            $(this).toggleClass('isActive');
        })

        /**
         *
         * Reset timeline nav
         *
         */
        function resetTimeline() {
            $('.js-timeline').removeClass('isOpen');
            $('.js-timeline-nav').removeClass('isOpen');
            $('.js-timeline-nav-toggle').removeClass('isOpen');

            var text = $('.js-timeline-nav-toggle').data('text');
            $('.js-timeline-nav-toggle').data('text', 'Close');
            $('.js-timeline-nav-toggle').find('span').text('Dates');
        }


        /**
         *
         * Anchor link to timeline item
         *
         */

        $('.js-timeline-anchor').on('click', function(evt) {
            evt.preventDefault();

            var target = $(this).data('target');
            var offset = $('.js-site-head').outerHeight();

            $('html,body').animate({
                scrollTop: $('#'+target).offset().top - offset - 30
            }, 1000);

            resetTimeline();
        });


        /**
         *
         * Show all timeline
         *
         */
        $('.js-toggle-timeline-all').on('click', function(evt) {
            evt.preventDefault();

            var $this = $(this);

            if(!$this.hasClass('isOpen')) {

                // activate nav
                timeLineOpen = true;

                // scroll to top
                var offset = $('.js-site-head').outerHeight();
                $('html, body').animate({
                    scrollTop: $('.js-timeline').offset().top - offset
                }, 500);

                // show timeline
                $('.js-timeline-category').removeClass('isHidden');
                $this.addClass('isOpen');

                setTimeout(function() {
                    AOS.refresh();
                }, 200);

            } else {

                // deactivate timeline
                timeLineOpen = false;

                // scroll to top
                var offset = $('.js-site-head').outerHeight();
                $('html, body').animate({
                        scrollTop: $('.js-timeline').offset().top - (offset + 30)
                }, 500);

                // delay closing to avoid scroll height glitch
                setTimeout(function() {
                    $('.js-timeline-category').addClass('isHidden');
                    $('.js-timeline-category--1').removeClass('isHidden');
                    $this.removeClass('isOpen');

                    AOS.refresh();
                }, 500);

            }

            var text = $this.text();
            $this.text($this.data('text-alt'));
            $this.data('text-alt', text);

        });


        /**
         *
         * Share options
         *
         */
        // blockquote hover sharing on articles
        // when user hovers over blockquote
        $('.timeline-post blockquote').each(function( index, element ) {
            var blockQuoteText = $(this).text();
            var currentUrl = window.location;
            var title = $(this).closest('article').find('h1').text();
            // add share buttons (change TwitterName to your Twitter handle for automatic mentions)
            // change paths to the icons to suit your installation (download link below)
            $(this).append('<div class="post-share-inline"><a href="#" class="post-share-inline__action  icon  icon-share"></a><div class="post-share-inline__list"><a class="icon  icon-social  icon-twitter-alt  js-social-share" href="http://twitter.com/intent/tweet?status=' +blockQuoteText+ '+' +currentUrl+ '+%40theianfleming"></a><a class="icon  icon-social  icon-facebook-alt  js-social-share" href="http://www.facebook.com/sharer/sharer.php?u=' +currentUrl+ '&amp;title=' +title+ '"></a><a class="icon  icon-social  icon-link-alt" href="mailto:?subject='+title+'&body=' +blockQuoteText+ '   ' +currentUrl+ '"></a></div></div>');

            $('.quote-share').hide().fadeIn(200);
        });
        $('.timeline-post p img').each(function( index, element ) {
            var imageUrl = $(this).attr('src');
            var currentUrl = window.location;
            var title = $(this).closest('article').find('h1').text();
            // add share buttons (change TwitterName to your Twitter handle for automatic mentions)
            // change paths to the icons to suit your installation (download link below)
            $(this).parent().css('position', 'relative');
            $(this).parent().append('<div class="post-share-inline"><a href="#" class="post-share-inline__action  icon  icon-share"></a><div class="post-share-inline__list"><a class="icon  icon-social  icon-twitter-alt  js-social-share" href="http://twitter.com/intent/tweet?status=Ian Fleming - ' +title+ '+' +imageUrl+ '+%40theianfleming"></a><a class="icon  icon-social  icon-link-alt" href="mailto:?subject=Ian Fleming Timeline - ' +title+ '&body=' +imageUrl+ '"></a></div></div>');

            $('.quote-share').hide().fadeIn(200);
        });
        $('.timeline-post .gallery').each(function( index, element ) {
            var currentUrl = window.location;
            var title = $(this).closest('article').find('h1').text();

            // add share buttons (change TwitterName to your Twitter handle for automatic mentions)
            // change paths to the icons to suit your installation (download link below)
            $(this).css('position', 'relative');
            $(this).append('<div class="post-share-inline"><a href="#" class="post-share-inline__action  icon  icon-share"></a><div class="post-share-inline__list"><a class="icon  icon-social  icon-twitter-alt  js-social-share" href="http://twitter.com/intent/tweet?status=Ian Fleming - ' +title+ '%40theianfleming"></a><a class="icon  icon-social  icon-link-alt" href="mailto:?subject=Ian Fleming Timeline - ' +title+ '&body=' +title+ '"></a></div></div>');

            $('.quote-share').hide().fadeIn(200);
        });
        $('.timeline-post .video-container').each(function( index, element ) {
            var currentUrl = $(this).find('iframe').attr('src');
            var title = $(this).closest('article').find('h1').text();

            // add share buttons (change TwitterName to your Twitter handle for automatic mentions)
            // change paths to the icons to suit your installation (download link below)
            $(this).css('position', 'relative');
            $(this).append('<div class="post-share-inline"><a href="#" class="post-share-inline__action  icon  icon-share"></a><div class="post-share-inline__list"><a class="icon  icon-social  icon-twitter-alt  js-social-share" href="http://twitter.com/intent/tweet?status=Ian Fleming - ' +title+ ' '+currentUrl+' %40theianfleming"></a><a class="icon  icon-social  icon-link-alt" href="mailto:?subject=Ian Fleming Timeline - ' +title+ '&body=' +currentUrl+ '"></a></div></div>');

            $('.quote-share').hide().fadeIn(200);
        });

        $('.post-share-inline__action').on('click', function(evt) {
            evt.preventDefault();
        });

    });

})(jQuery); // Fully reference jQuery after this point.