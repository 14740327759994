(function($) {

    // Load Events
    $(window).load(function() {

        setTimeout(function() {
            $('.js-slick-bond-carousel').slick();
            $('.js-bond-slide-load').removeClass('isVisible');
        }, 1000);

        $('.js-slick-bond-nav').click(function(evt) {
            evt.preventDefault();

            var direction = $(this).data('direction');

            $('.js-bond-slide-load').addClass('isVisible');

            setTimeout(function() {
                if(direction === 'prev') {
                    $(".js-slick-bond-carousel").slick('slickPrev');
                } else {
                    $(".js-slick-bond-carousel").slick('slickNext');
                }

                $('.js-bond-slide-load').removeClass('isVisible');

            }, 1000);

            // clear open slides
            setTimeout(function() {
                $('.js-bond-slide').removeClass('isOpen');
            }, 1000)
        });



        $('.js-bond-slide-open').on('click', function(evt) {
            evt.preventDefault();

            $(this).closest('.js-bond-slide').toggleClass('isOpen');

            // toggle text
            var $textButton = $(this).closest('.js-bond-slide').find('.js-bond-slide-open-text');
            var newText = $textButton.data('alt');
            var currentText = $textButton.text();
            $textButton.data('alt', currentText).text(newText);
        });



    });

})(jQuery); // Fully reference jQuery after this point.
